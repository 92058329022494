import { request } from '@/plugins/axios/index'

// 信息列表
export function infoListHttp(params: any) {
  return request({
    url: `/api/system/convenient/info/page`,
    method: 'get',
    params,
  })
}

// 新建便民信息
export function addInfoHttp(data: any) {
  return request({
    url: `/api/system/convenient/info`,
    method: 'post',
    data,
  })
}
