import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { Swipe, SwipeItem, Tab, Tabs, Sticky } from 'vant';
import { createPinia } from 'pinia';
// 2. 引入组件样式
import 'vant/lib/index.css';

import 'element-plus/dist/index.css'

const pinia = createPinia();

createApp(App).use(pinia).use(router).use(ElementPlus, {
  locale: zhCn,
}).use(Swipe).use(SwipeItem).use(Tab).use(Sticky).use(Tabs).mount('#app')
