import { request } from '@/plugins/axios/index'

// 商户列表
export function tenantListHttp(params: any) {
  return request({
    url: `/api/system/tenant/page`,
    method: 'get',
    params,
  })
}

// 启用停用
export function changeStatusHttp(params: any) {
  return request({
    url: `/api/system/tenant/change-status`,
    method: 'put',
    params,
  })
}

// 新增商户
export function addTenantHttp(data: any) {
  return request({
    url: `/api/system/tenant`,
    method: 'post',
    data,
  })
}

// 编辑商户
export function editTenantHttp(data: any) {
  return request({
    url: `/api/system/tenant`,
    method: 'put',
    data,
  })
}

// 商户详情
export function tenantDetailHttp(params: any) {
  return request({
    url: `/api/system/tenant/detail`,
    method: 'get',
    params,
  })
}

// 商户管理员
export function shopManagerHttp(params: any) {
  return request({
    url: `/api/system/tenant/manager/list`,
    method: 'get',
    params,
  })
}

// 删除商户管理员
export function delManagerHttp(params: any) {
  return request({
    url: `/api/system/tenant/manager`,
    method: 'delete',
    params,
  })
}

// 新增商户管理员
export function addManagerHttp(data: any) {
  return request({
    url: `/api/system/tenant/manager`,
    method: 'post',
    data,
  })
}
