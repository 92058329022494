import { request } from '@/plugins/axios/index'

// 新增商品
export function addGoodsHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/insert-spu`,
    method: 'post',
    data,
  })
}

// 上传图片
export function uploadImgHttp(data: any) {
  return request({
    url: `/common/upload/file`,
    method: 'post',
    data,
  })
}

// 商品列表
export function goodsListHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-page`,
    method: 'get',
    params,
  })
}

// 商品数量
export function goodsNumHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-count`,
    method: 'get',
    params,
  })
}

// 商品详情
export function goodsInfoHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-detail`,
    method: 'get',
    params,
  })
}

// 上下架商品
export function goodsOffOnHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/update-spu-status`,
    method: 'put',
    data,
  })
}

// 编辑商品
export function goodsEditHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/update-spu`,
    method: 'put',
    data,
  })
}

// 商品订单数量
export function goodsOrderNumHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-order-count`,
    method: 'get',
    params,
  })
}

// 新增小区
export function addParkHttp(data: any) {
  return request({
    url: `/api/system/park`,
    method: 'post',
    data,
  })
}

// 父级小区
export function rootParkHttp(params: any) {
  return request({
    url: `/api/system/park/root`,
    method: 'get',
    params,
  })
}

// 所有商户
export function allTenantHttp() {
  return request({
    url: `/api/system/tenant/all`,
    method: 'get',
  })
}

// 省市
export function areaTreeHttp() {
  return request({
    url: `/common/area/tree`,
    method: 'get',
  })
}

// 小区列表
export function parkListHttp(params: any) {
  return request({
    url: `/api/system/park/page`,
    method: 'get',
    params
  })
}

// 小区客服列表
export function parkManagerHttp(params: any) {
  return request({
    url: `/api/system/park/manager/list`,
    method: 'get',
    params
  })
}

// 编辑
export function editParkHttp(data: any) {
  return request({
    url: `/api/system/park`,
    method: 'put',
    data
  })
}

// 删除小区
export function delParkHttp(params: any) {
  return request({
    url: `/api/system/park`,
    method: 'delete',
    params
  })
}

// 小区详情
export function parkDetailHttp(params: any) {
  return request({
    url: `/api/system/park/detail`,
    method: 'get',
    params
  })
}
