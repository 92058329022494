// stores/counterStore.js
import { defineStore } from 'pinia';
 
export const useCounterStore = defineStore({
  id: 'counter',
  state: () => ({
    // 租户id
    tenantId: '',
  }),
  actions: {
    setTenantId(id: any) {
      this.tenantId = id
    },
    clearTenantId() {
      this.tenantId = ''
    }
  },
});