import axios, { AxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import router from '../../router'

// 测试环境
// export const baseURL = 'https://www.dean-hu.cn:8080'
// 生产环境
export const baseURL = 'https://www.zero-mi.com/api'

// 创建一个 axios 实例
const service = axios.create({
  baseURL,
  timeout: 20000, // 请求超时时间
  headers: {
    'Zero-Authorization': localStorage.getItem('token') || '',
  },
})
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    config.headers['Zero-Authorization'] =
      localStorage.getItem('token') != null ? localStorage.getItem('token') : ''
    return config
  },
  (error) => {
    console.log('axios request:', error)
    Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const dataAxios = response.data
    console.log(1, dataAxios)
    const { code, message, success, status } = dataAxios
    if (code === 'SUCCESS') {
      if (dataAxios.totalCount != undefined && dataAxios.pageNum) {
        return dataAxios
      } else {
        return dataAxios.data
      }
    } else {
      if (response.config.responseType == 'blob') {
        return dataAxios
      } else {
        ElMessage.error(message || '请求出错')
        throw new Error(message || '请求出错')
      }
    }
  },
  (error) => {
    if (error.response.status == 401) {
      // localStorage.removeItem('token')
      router.push({
        path: '/login'
      })
    } else {
      ElMessage.error(error?.message)
      return Promise.reject(error)
    }
    
  }
)

export function request<T>(config: AxiosRequestConfig): Promise<T> {
  return service.request(config)
}

export default service