import { request } from '@/plugins/axios/index'

// 新增商品
export function addGoodsHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/insert-spu`,
    method: 'post',
    data
  })
}

// 上传图片
export function uploadImgHttp(data: any) {
  return request({
    url: `/common/upload/file`,
    method: 'post',
    data
  })
}

// 商品列表
export function goodsListHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-page`,
    method: 'get',
    params
  })
}

// 商品数量
export function goodsNumHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-count`,
    method: 'get',
    params
  })
}

// 商品详情
export function goodsInfoHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-detail`,
    method: 'get',
    params
  })
}

// 上下架商品
export function goodsOffOnHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/update-spu-status`,
    method: 'put',
    data
  })
}

// 编辑商品
export function goodsEditHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/update-spu`,
    method: 'put',
    data
  })
}

// 商品订单数量
export function goodsOrderNumHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu-order-count`,
    method: 'get',
    params
  })
}

// 复制创建
export function copyCreateGoodsHttp(data: any) {
  return request({
    url: `/api/system/goods-manager/copy-create`,
    method: 'post',
    data
  })
}

// 删除商品
export function delGoodsHttp(params: any) {
  return request({
    url: `/api/system/goods-manager/spu`,
    method: 'delete',
    params
  })
}
