import { request } from '@/plugins/axios/index'

// 服务商列表
export function serviceShopListHttp(params: any) {
  return request({
    url: `/api/system/shop/tenant-service-shop/page`,
    method: 'get',
    params,
  })
}

// 新增服务商
export function addServiceShopHttp(data: any) {
  return request({
    url: `/api/system/shop/tenant-service-shop`,
    method: 'post',
    data,
  })
}

// 修改服务商
export function editServiceShopHttp(data: any) {
  return request({
    url: `/api/system/shop/tenant-service-shop`,
    method: 'put',
    data,
  })
}

// 服务商师傅
export function serviceShopTechnicianListHttp(params: any) {
  return request({
    url: `/api/system/shop/tenant-service-shop/technician`,
    method: 'get',
    params,
  })
}