import { request } from '@/plugins/axios/index'

// 手机号查人
export function phoneSearchUserHttp(params: any) {
  return request({
    url: `/api/system/user/get-by-phone`,
    method: 'get',
    params
  })
}

// 添加运营人员
export function addPeopleHttp(data: any) {
  return request({
    url: `/api/system/user/operational/people`,
    method: 'put',
    data
  })
}
