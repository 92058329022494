<template>
  <router-view/>
</template>

<style lang="scss">
html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
}
p {
  margin: 0;
  padding: 0;
}
</style>
