import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import { useCounterStore } from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // name: 'Home',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, _from, next) => {
  const token = localStorage.getItem('token')
  const counterStore = useCounterStore()
  if (!counterStore.tenantId && token) {
    const tenantId = localStorage.getItem('tenantId')
    counterStore.setTenantId(tenantId)
  }
  if (!token && to.path != '/login') {
    next('/login')
  } else {
    next()
  } 
})


export default router
