import { request } from '@/plugins/axios/index'

// 登录
export function loginHttp(data: any) {
  return request({
    url: `/api/login`,
    method: 'post',
    data
  })
}

// 获取订单列表
export function orderListHttp(params: any) {
  return request({
    url: `/api/order/page`,
    method: 'get',
    params
  })
}

// 取消订单
export function cancelOrderHttp(data: any) {
  return request({
    url: `/api/order/cancel`,
    method: 'put',
    data
  })
}

// 查询服务商
export function shopListHttp(params: any) {
  return request({
    url: `/api/order/shop/list`,
    method: 'get',
    params
  })
}

// 查询师傅
export function workerListHttp(params: any) {
  return request({
    url: `/api/order/technician/list`,
    method: 'get',
    params
  })
}

// 修改上门时间
export function editDateHttp(data: any) {
  return request({
    url: `/api/order/visit/time`,
    method: 'put',
    data
  })
}

// 分配师傅
export function technicianHttp(data: any) {
  return request({
    url: `/api/order/allot/technician`,
    method: 'put',
    data
  })
}

// 商品分类
export function categoryListHttp(params: any) {
  return request({
    url: `/api/order/goods/category`,
    method: 'get',
    params
  })
}

// 用户列表
export function userListHttp(params: any) {
  return request({
    url: `/api/system/user/page`,
    method: 'get',
    params
  })
}

// 用户详情
export function userAuthorityHttp(params: any) {
  return request({
    url: `/api/system/user/authority`,
    method: 'get',
    params
  })
}

// 所有服务商列表
export function tenantServiceShopListHttp(params: any) {
  return request({
    url: `/api/system/shop/tenant-service-shop`,
    method: 'get',
    params
  })
}

// 所有小区列表
export function parkListHttp(params: any) {
  return request({
    url: `/api/system/park/all`,
    method: 'get',
    params
  })
}

// 修改用户权限
export function editUserAuthorityHttp(data: any) {
  return request({
    url: `/api/system/user/authority`,
    method: 'put',
    data
  })
}

// 导出
export function exportHttp(params: any) {
  return request({
    url: `/api/order/excel/export`,
    method: 'get',
    responseType: 'blob',
    params
  })
}
